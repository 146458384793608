<template>
  <div class="view docs">
    <navbar />
    <main class="flex-container">
      <div class="content">
        <header>
          <h1>Documentation</h1>
          <div class="alert warning">This site is currently in a public beta testing period. Click <b><router-link to="/beta">here</router-link></b> for more information.</div>
        </header>
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'Docs',
  components: { Navbar },
}
</script>

<style lang="scss">
.docs {
  .alert {
    margin: 1rem 0;
  }

  .content {
    * { clear: both; }

    p.hint {
      color: var(--c-info);
      font-style: italic;
      text-align: center;
      padding: 0 clamp(0, 5%, 10%);
    }

    .table-of-contents {
      border: 1px solid var(--c-border);
      border-radius: 5px;
      padding: 1rem 2.5rem;
      margin-bottom: 2rem;
      background: var(--c-bg-shade);
      float: left;
      clear: both;

      &, ol {
        counter-reset: item;
      }

      li {
        font-weight: 600;
        list-style: none;

        &::before {
          content: counters(item, ".") ". ";
          counter-increment: item;
          margin-right: 1ch;
        }
      }

      li + li,
      ol li:first-child {
        margin-top: 0.5rem;
      }
    }

    article + article {
      margin-top: 0.5rem;
    }
  }
}
</style>
